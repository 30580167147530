<template>
  <div>
    <v-col>
      <v-row>
        <v-col>
          <s-crud
            title="Reporte de Reservas por Trabajador"
            :filter="filter"
            :config="config"
            @doubleClick="doubleClick($event)"
            excel
          >
            <template v-slot:filter>
              <v-container>
                <v-row justify="center">
                  <v-col lg="2"
                    ><s-switch v-model="isVisit" label="Visitas"></s-switch>
                  </v-col>

                  <v-col lg="2" class="s-col-form">
                    <s-select-definition
                      label="Tipo Planilla"
                      clearable
                      v-model="filter.TypeWorker"
                      :def="1060"
                    ></s-select-definition>
                  </v-col>
                  <v-col
                    lg="2"
                    v-if="filter.TypeWorker != 0"
                    class="s-col-form"
                  >
                    <s-select
                      label="Año"
                      :items="years"
                      outlined
                      hide-details
                      dense
                      v-model="year"
                    ></s-select>
                  </v-col>
                  <v-col
                    lg="2"
                    v-if="
                      filter.TypeWorker == 1 ||
                      filter.TypeWorker == 3 ||
                      filter.TypeWorker == 4 ||
                      filter.TypeWorker == 7 ||
                      filter.TypeWorker == 8
                    "
                    class="s-col-form"
                  >
                    <s-select
                      label="Mes"
                      :items="itemsMonth"
                      outlined
                      hide-details
                      dense
                      v-model="month"
                    ></s-select>
                  </v-col>

                  <v-col
                    class="s-col-form"
                    lg="2"
                    v-if="
                      filter.TypeWorker == 99 ||
                      filter.TypeWorker == 2 ||
                      filter.TypeWorker == 5 ||
                      filter.TypeWorker == 6
                    "
                  >
                    <s-select
                      label="Semana"
                      dense
                      hide-details
                      outlined
                      :items="itemsWeek"
                      v-model="week"
                    ></s-select>
                  </v-col>

                  <v-col
                    lg="1"
                    v-if="filter.TypeWorker != 0"
                    class="s-col-form"
                  >
                    <v-btn
                      small
                      style="margin-top: 16px"
                      color="primary"
                      @click="selection()"
                      >Seleccionar</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col lg="2" class="s-col-form">
                    <s-date
                      label="Fecha de Inicio"
                      v-model="filter.BeginDate"
                    ></s-date>
                  </v-col>
                  <v-col lg="2" class="s-col-form">
                    <s-date
                      label="Fecha de Fin"
                      v-model="filter.EndDate"
                    ></s-date>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </s-crud>
        </v-col>
      </v-row>
    </v-col>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        v-if="dialog"
        width="100%"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar elevation="0" rounded="" dense>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ this.filter.Worker }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text @click="dialog = false">Ok</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-col>
            <s-crud
              noBorder
              edit
              title="Detalle de reporte de Consumos"
              @save="saveReserve($event)"
              :filter="filter"
              :config="configdetail"
              excel
            >
              <template scope="props">
                <v-col>
                  <v-row>
                    <v-col lg="3">
                      <s-text
                        label="Descuento"
                        v-model="props.item.McsDiscount"
                      ></s-text>
                    </v-col>
                    <v-col lg="3">
                      <s-select-definition
                        label="Tipo de Costo"
                        :def="1045"
                        v-model="props.item.TypeCost"
                      ></s-select-definition>
                    </v-col>
                    <v-col lg="6">
                      <s-text
                        label="observacion"
                        v-model="props.item.McsObservation"
                      ></s-text>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <template v-slot:TypeCost="{ row }">
                {{ row.TypeCost == 1 ? "COSTO FIJO" : "COSTO VARIABLE" }}
              </template>
            </s-crud>
          </v-col>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import MealServiceReport from "./../../services/DinningHall//MealConsumptionReportService";
import MealServiceReportDetail from "./../../services/DinningHall//MealConsumptionReportDetailService";
import MealConsumption from "./../../services/DinningHall/MealConsumptionService";
export default {
  name: "ConsumptionReport",
  components: {},
  data: () => ({
    itemsMonth: [],
    itemsWeek: [],
    years: [],
    month: 0,
    week: 0,
    year: 0,
    isVisit: 0,
    filter: {
      BeginDate: null,
      EndDate: null,
      AreID: 0,
      NtpID: 0,
      TypeWorker: 0,
    },
    dialog: false,
    config: {
      model: { NtpID: "ID" },
      service: MealServiceReport,
      headers: [
        { text: "ID", value: "NtpID" },
        { text: "Nombre Completo", value: "McsPersonName" },
        { text: "Documento", value: "McsPersonDocumentNumber" },
        // { text: "Centro de Costo", value: "TextCenterCost" },
        // { text: "Área", value: "AreaText" },
        { text: "Total Descuento", value: "McsDiscount" },
        //{ text: "Total", value: "McsPrice" },
        { text: "#Reservas", value: "McsQuantity" },
      ],
    },
    configdetail: {
      model: {
        McsID: "ID",
        McsConsumptionDate: "datetime",
        TypeCost: "string",
        McsReservationDate: "date",
        SecCreate: "datetime",
      },
      service: MealServiceReportDetail,
      headers: [
        { text: "ID", value: "McsID" },
        { text: "Plato", value: "TypeMealText" },
        { text: "Menú", value: "MenuTypeText" },
        { text: "Contenido", value: "ContentText" },
        { text: "F.Atención", value: "McsReservationDate" },
        { text: "F.Reservación", value: "SecCreate" },
        //   { text: "H. Reserva", value: "SecCreate" },
        { text: "F.Consumo", value: "McsConsumptionDate" },
        { text: "Descuento", value: "McsDiscount" },
        { text: "Tipo de Costo", value: "TypeCost" },
      ],
    },
    beginDate: null,
    endDate: null,
    img: "",
    document: "",
    mealConsumption: null,
    meals: [],
    lengthMeals: 4,
  }),
  watch: {
    isVisit() {
      if (this.isVisit == 1) this.filter.TypeWorker = 99;
    },
    "filter.TypeWorker"() {
      if (this.filter.TypeWorker != 99 && this.filter.TypeWorker != 0) {
        this.isVisit = 0;
      }
      this.month = 1;
      this.week = 1;
    },
    year() {
      this.itemsWeek = this.$fun.getNumberWeeksYear(this.year);
      this.week = 1;
    },
  },
  created() {
    this.itemsMonth = this.$const.Months;

    this.years = this.$fun.getTop5LastYears();
    this.year = this.$moment().year();
    this.filter.EndDate = this.$moment().format("YYYY-MM-DD");
    this.filter.BeginDate = this.$moment().format("YYYY-MM-DD");
    this.selection();
  },
  mounted() {},

  methods: {
    selection() {
      // semanales
      if (
        this.filter.TypeWorker == 99 ||
        this.filter.TypeWorker == 2 ||
        this.filter.TypeWorker == 5 ||
        this.filter.TypeWorker == 6
      ) {
        var mondayYear = this.$moment(this.year + "-01-01").day(1);
        let year = mondayYear.year();
        if (year != this.year) {
          mondayYear = this.$moment(this.year + "-01-01")
            .day(1)
            .add(7, "days");
        }

        this.filter.BeginDate = mondayYear
          .add((this.week - 1) * 7, "days")
          .format("YYYY-MM-DD");

        console.log("fecha inicio filtro", this.filter.BeginDate);
        this.filter.EndDate = mondayYear.add(6, "days").format("YYYY-MM-DD");
        // mensuales
      } else if (
        this.filter.TypeWorker == 1 ||
        this.filter.TypeWorker == 4 ||
        this.filter.TypeWorker == 3 ||
        this.filter.TypeWorker == 7 ||
        this.filter.TypeWorker == 8
      ) {
        let dd = 19;
        let m = this.month - 1;

        if (m < 1)
          this.filter.BeginDate = this.$moment(this.year - 1 + "1220").format("YYYY-MM-DD");
        else {
          let mo = m <= 9 ? "0" + m : m;
          this.filter.BeginDate = this.$moment(this.year.toString() + mo.toString() + "01").add(dd, "days").format("YYYY-MM-DD");
        }

        console.log('fecha de inicio filtro mes', this.filter.BeginDate)

        let d = 18;
        this.filter.EndDate = this.$moment(this.year.toString() +"" + (this.month <= 9 ? "0" + this.month.toString() : this.month) + "01").add(d, "days")
          .format("YYYY-MM-DD");

        console.log('fecha de fin filtro mes', this.filter.EndDate)

      }
    },
    saveReserve(item) {
      if (item.McsObservation.trim().length == 0) {
        this.$fun.alert("Falta Observación", "warning");
        return;
      }
      item.save();
    },
    doubleClick(value) {
      this.filter.NtpID = value.NtpID;
      this.filter.Worker = value.McsPersonName;
      this.dialog = true;
    },
  },
};
</script>
