import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {

    pagination(dtr) {
        return Vue.prototype.$http.post(menu + "platospersonaplanilla", dtr);
    },

    excel(dtr) {
        return Vue.prototype.$http.post(menu + "reporteplatospersona", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};